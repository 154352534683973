import { useContext } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';

import { LanguageContext } from 'context/Language';

import { Banner, Results } from 'types';

const Col = dynamic(() => import('components/Grid/Col'));
const Row = dynamic(() => import('components/Grid/Row'));
const Link = dynamic(() => import('components/Link'));
const Responsive = dynamic(() => import('components/Responsive'));

interface NavigationSettingsProps {
  specialBanners: Results<Banner>;
}

const NavigationSettings: React.FC<NavigationSettingsProps> = ({ specialBanners }) => {
  const { lang } = useContext(LanguageContext);

  return (
    <>
      <Row g={4} className="navigation-settings">
        {specialBanners?.results?.map((el) => {
          let i18n;
          try {
            i18n = JSON.parse(el.i18n[lang] as string);
          } catch (e) {
            i18n = { title: el.i18n[lang], content: el.i18n[lang], link: el.i18n[lang] };
          }
          return (
            <Col size={12} md={6} lg={4} className="navigation-settings__item" key={el.id}>
              <Link
                href={
                  /(http:\/\/|https:\/\/)/.test(`${i18n.link}`) ? i18n.link : `${lang}${i18n.link}`
                }
                isTarget={/(http:\/\/|https:\/\/)/.test(`${i18n.link}`)}
                className="item__link"
              >
                <Responsive until="small-desktop">
                  <h3 className="item-title">{i18n.title}</h3>
                </Responsive>
                <div className="item__box">
                  <Image src={el.image} layout="fill" />
                  <div className="background-gradient">
                    <Responsive>
                      <h3 className="item-title">{i18n.title}</h3>
                    </Responsive>
                  </div>
                </div>
              </Link>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default NavigationSettings;
